<template>
  <div class="card trending-card">
    <div class="card-body">
      <h5 class="card-title">
        <a :href="repo.url" target="_blank" rel="noopener noreferrer">{{ repo.name }}</a>
      </h5>
      <p class="card-text" :title="repo.description">{{ truncateDescription }}</p>
      <div class="card-info">
        <span class="info-item">
          <font-awesome-icon icon="code" />
          {{ repo.language }}
        </span>
                <span class="info-item">
          <font-awesome-icon icon="star" />
          {{ repo.stars }}
        </span>
                <span class="info-item">
          <font-awesome-icon icon="code-branch" />
          {{ repo.forks }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrendingCard",
  props: {
    repo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    truncateDescription() {
      if (this.repo.description && this.repo.description.length > 100) {
        return this.repo.description.substring(0, 100) + "...";
      } else if (this.repo.description) {
        return this.repo.description;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-body {
  padding: 20px;
}

.card-title {
  margin-bottom: 15px;
}

.card-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
}

.card-info {
  display: flex;
  justify-content: space-between;
}

.info-item {
  display: flex;
  align-items: center;
}

.info-item i {
  margin-right: 5px;
}
</style>
