import axios from "axios";



export async function getTrendingRepos() {
    const response = await axios.create({
        baseURL: "https://v-api.yi-lab.net",
        withCredentials: false,
        headers: {
            Accept: "application/vnd.github+json",
            "Content-Type": "application/json",
        },
    }).get("/api/hello")
    return response.data;
}
//
// const apiClient = axios.create({
//     baseURL: "https://api.github.com",
//     withCredentials: false,
//     headers: {
//         Accept: "application/vnd.github+json",
//         "Content-Type": "application/json",
//     },
// });

//
// async function getTrendingRepos0() {
//     // ...
//     const response = await apiClient.get("/search/repositories", {
//         params: {
//             q: "stars:>=1000", // 获取至少有1000颗星的仓库
//             sort: "updated", // 按更新时间排序
//             order: "desc",
//             per_page: 200, // 获取200个仓库
//         },
//     });
//     const shuffledRepos = shuffle(response.data.items);
//     const randomFiftyRepos = shuffledRepos.slice(0, 50);
//     return randomFiftyRepos.map(mapRepoData);
// }
//
// function shuffle(array) {
//     let currentIndex = array.length,
//         temporaryValue,
//         randomIndex;
//
//     while (currentIndex !== 0) {
//         randomIndex = Math.floor(Math.random() * currentIndex);
//         currentIndex -= 1;
//
//         temporaryValue = array[currentIndex];
//         array[currentIndex] = array[randomIndex];
//         array[randomIndex] = temporaryValue;
//     }
//
//     return array;
// }
//
// function mapRepoData(repo) {
//     return {
//         id: repo.id,
//         name: repo.full_name,
//         description: repo.description,
//         url: repo.html_url,
//         language: repo.language,
//         stars: repo.stargazers_count,
//         forks: repo.forks_count,
//     };
// }
