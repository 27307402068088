<template>
  <div id="app">
    <h1>Github Trending Repositories</h1>
    <div class="repo-container">
      <div
          v-for="(repo, index) in trendingRepos"
          :key="repo.id"
          class="card-container"
          :style="shuffleAnimation(index)"
      >
        <trending-card :repo="repo" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import TrendingCard from "./components/TrendingCard.vue";
import { getTrendingRepos } from "./api";
import "animate.css/animate.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import anime from "animejs";

export default {
  name: "App",
  components: {
    TrendingCard,
  },
  setup() {
    const trendingRepos = ref([]);

    onMounted(async () => {
      AOS.init({
        delay: 100,
        duration: 1500,
      });
      await fetchTrendingRepos();
      setInterval(fetchTrendingRepos, 60000);
    });

    async function fetchTrendingRepos() {
      trendingRepos.value = await getTrendingRepos();
      await nextTick(); // 等待 DOM 更新
      animateCards();
    }

    function animateCards() {
      anime({
        targets: ".trending-card",
        keyframes: [
          { translateY: -100, opacity: 0, duration: 0 },
          { translateY: 0, opacity: 1, duration: 500 },
        ],
        delay: anime.stagger(100), // 每个元素间的动画延迟
        easing: "easeOutQuad",
      });
    }

    function shuffleAnimation(index) {
      return {
        animation: `shuffle 0.5s ${index * 0.1}s forwards`,
      };
    }

    return {
      trendingRepos,
      shuffleAnimation,
    };
  },

};
</script>

<style>
#app {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  background-color: #f8f9fa;
  color: #343a40;
  padding: 60px 0;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.repo-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
  padding: 2rem;
}

@keyframes shuffle {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

</style>
